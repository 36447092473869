@font-face {
  font-family: 'Super Normal';
  src: local('Super Normal'), url("../public/fonts/Super Normal.ttf");
}

.js-loading *,
.js-loading *:before,
.js-loading *:after {
  animation-play-state: paused !important;
}

html, body, #root, .App {
  width:100%;
  height: 100%;
}

.App {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: white;
  transform-style: preserve-3d;
  perspective: 800px;
  perspective-origin: center;
  background: linear-gradient(to bottom, #020111 10%,#3a3a52 100%);
}

nav {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: rgb(50 50 50);
  border: 0.5rem solid rgb(30 30 30);
  overflow: hidden;
  box-shadow: 12px 25px 50px 12px rgb(0 0 0 / 0.5);
}

a {
  color: blue;
  text-decoration: none; /* no underline */
}

nav > .content {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
  margin: 0 auto;
  max-width: 60rem;
  min-height: 5rem;
}

nav > .content > * {
  flex: 1 1 auto;
  min-width: min-content;
  color: white;
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
}

nav::before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-image: url("../public/repeatable_wave.webp");
  opacity: 0.3;
  animation: move-background 50s linear infinite;
}

nav .title {
  color: yellow;
  font-family: 'Super Normal';
  font-size: 1.5rem;
  font-weight: bold;
  max-width: 100%;
  transform: scale(0.0);
  transition: transform 0.15s;
}

nav.sticky .title {
  transform: scale(1.0);
}

nav .title > object {
  display: inline-block;
}

nav .title > .text {
  display: inline-block;
}

@media only screen and (max-width: 500px) {
  nav .text {
    display: none;
  }
}

main {
  background: black;
  z-index: 1;
  position: relative;
}

main .container {
  display: grid;
  place-items: center;
}

main .container .box {
  font-family: 'Super Normal';
  position: relative;
  z-index: 3;
  color: yellow;
  background: black;
  border: 0.5rem solid Olive;
  border-top-right-radius: 5rem;
  border-bottom-left-radius: 5rem;
  box-shadow: 0.5rem 0.5rem 10rem rgb(37, 37, 0);
  margin: 1rem;
  padding: 3rem;
  min-width: 18rem;
  text-align: center;

  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  min-height: 20rem;
}

.strips {
  overflow-x: hidden;
  transform: translate(0, -50%);
}

.strips .strip {
  width: 110%;
  height: 5rem;
  transform: translate(-50%, 0) rotate(-1deg);
}

footer {
  height: 100%;
  background: black;
  position: relative;
}

@keyframes move-background {
  0% {
    background-position: 0% 0%;
  }
  
  100% {
    background-position: -100% 0%;
  }
}
